// src/components/AboutUs.js
import React from 'react';

function AboutUs() {
  return (
    <div id="about" className="container mx-auto px-6 py-16">
      <div className="text-gray-800 space-y-12">
        <div className="text-center max-w-3xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold">
            Our Journey to Transforming Businesses
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Our founders have seen every way of working you can imagine.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-12">
          <div className="space-y-6">
            <p>
              As they worked with clients, they noticed most were constantly disappointed...
            </p>
            {/* Add more content as needed */}
          </div>
          <div className="space-y-6">
            <p>
              Our founders felt there was a better approach than what was being offered...
            </p>
            {/* Add more content as needed */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
