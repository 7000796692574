import React, { useState } from 'react';
import logo from '../assets/images/base_logo_transparent_background.png';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="sticky top-0 z-50 w-full bg-white px-4 py-4 border-b border-gray-200">
      <div className="max-w-5xl mx-auto flex items-center justify-between">
        {/* Logo Section */}
        <div className="text-xl font-bold">
          <a href="/">
            <img src={logo} alt="Logo" className="h-10" />
          </a>
        </div>

        {/* Mobile menu button (only visible on small screens) */}
        <button
          type="button"
          className="md:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Toggle menu"
        >
          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            {isOpen ? (
              // "X" icon when menu is open
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              // Hamburger lines when menu is closed
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>

        {/* Desktop Menu (visible on md and larger screens) */}
        <ul className="hidden md:flex md:space-x-6">
          <li>
            <a
              href="/about"
              className="relative block text-gray-500 hover:text-gray-900 no-underline group py-1"
            >
              <span className="whitespace-nowrap">About</span>
              {/* Decorative lines */}
              <span className="absolute top-0 left-0 h-px w-5 overflow-hidden">
                <span className="border-b border-gray-900 block absolute left-0 top-0 w-full transition-transform duration-200 transform translate-x-full group-hover:translate-x-0"></span>
                <span className="border-b border-gray-900 block absolute left-0 top-0 w-full transition-transform duration-200 transform -translate-x-full group-hover:translate-x-0"></span>
              </span>
            </a>
          </li>
          <li>
            <a
              href="/services"
              className="relative block text-gray-500 hover:text-gray-900 no-underline group py-1"
            >
              <span className="whitespace-nowrap">Services</span>
              {/* Decorative lines */}
              <span className="absolute top-0 left-0 h-px w-5 overflow-hidden">
                <span className="border-b border-gray-900 block absolute left-0 top-0 w-full transition-transform duration-200 transform translate-x-full group-hover:translate-x-0"></span>
                <span className="border-b border-gray-900 block absolute left-0 top-0 w-full transition-transform duration-200 transform -translate-x-full group-hover:translate-x-0"></span>
              </span>
            </a>
          </li>
          <li>
            <a
              href="/testimonials"
              className="relative block text-gray-500 hover:text-gray-900 no-underline group py-1"
            >
              <span className="whitespace-nowrap">Testimonials</span>
              {/* Decorative lines */}
              <span className="absolute top-0 left-0 h-px w-5 overflow-hidden">
                <span className="border-b border-gray-900 block absolute left-0 top-0 w-full transition-transform duration-200 transform translate-x-full group-hover:translate-x-0"></span>
                <span className="border-b border-gray-900 block absolute left-0 top-0 w-full transition-transform duration-200 transform -translate-x-full group-hover:translate-x-0"></span>
              </span>
            </a>
          </li>
          <li>
            <a
              href="/contact"
              className="relative block text-gray-500 hover:text-gray-900 no-underline group py-1"
            >
              <span className="whitespace-nowrap">Contact</span>
              {/* Decorative lines */}
              <span className="absolute top-0 left-0 h-px w-5 overflow-hidden">
                <span className="border-b border-gray-900 block absolute left-0 top-0 w-full transition-transform duration-200 transform translate-x-full group-hover:translate-x-0"></span>
                <span className="border-b border-gray-900 block absolute left-0 top-0 w-full transition-transform duration-200 transform -translate-x-full group-hover:translate-x-0"></span>
              </span>
            </a>
          </li>
        </ul>
      </div>

      {/* Mobile Dropdown Menu (visible when isOpen is true on small screens) */}
      {isOpen && (
        <ul className="md:hidden mt-4 space-y-2">
          <li>
            <a
              href="/about"
              className="block text-gray-500 hover:text-gray-900 no-underline py-2"
            >
              About
            </a>
          </li>
          <li>
            <a
              href="/services"
              className="block text-gray-500 hover:text-gray-900 no-underline py-2"
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="/testimonials"
              className="block text-gray-500 hover:text-gray-900 no-underline py-2"
            >
              Testimonials
            </a>
          </li>
          <li>
            <a
              href="/contact"
              className="block text-gray-500 hover:text-gray-900 no-underline py-2"
            >
              Contact
            </a>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navigation;
