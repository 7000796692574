// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation'; // Updated import
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="font-sans antialiased text-gray-900">
        <Navigation />
        <main>
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/contact" element={<ContactUs />} />
            {/* Add a catch-all route for 404 Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

// Optional: Create a NotFound component for undefined routes
const NotFound = () => (
  <div className="max-w-5xl mx-auto p-4">
    <h1 className="text-3xl font-bold">404 - Page Not Found</h1>
    <p className="mt-2">Sorry, the page you're looking for doesn't exist.</p>
  </div>
);

export default App;
