// src/components/Hero.js
import React from 'react';
import { FaArrowDown } from 'react-icons/fa';

function Hero() {
  return (
    <div className="relative h-screen flex items-center justify-center bg-gradient-to-br from-primary via-secondary to-accent">
      <div className="absolute inset-0 bg-hero-pattern opacity-30"></div>
      <div className="container mx-auto px-6 relative z-10 text-center">
        <h1 className="text-5xl md:text-7xl font-extrabold text-white leading-tight animate-fade-in-down">
          Welcome to <span className="text-yellow">Oknenor</span>
        </h1>
        <p className="mt-6 text-xl md:text-2xl text-white">
          Building scalable, sustainable growth for your brand—together
        </p>
        <a
          href="/about"
          className="mt-8 inline-flex items-center bg-yellow text-gray-900 px-8 py-4 rounded-full text-lg font-semibold hover:bg-yellow-500 transition duration-300"
        >
          Learn More
          <FaArrowDown className="ml-2 animate-bounce" />
        </a>
      </div>
    </div>
  );
}

export default Hero;
