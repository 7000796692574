// src/components/ContactUs.js
import React, { useState } from 'react';

function ContactUs() {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev) => ({ ...prev, [e.target.name]: '' }));
  };

  const validate = () => {
    let errors = {};
    if (!formState.name) errors.name = 'Name is required.';
    if (!formState.email) errors.email = 'Email is required.';
    if (!formState.message) errors.message = 'Message is required.';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      // Handle form submission
      console.log('Form submitted:', formState);
      setFormState({ name: '', email: '', message: '' });
      setFormErrors({});
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), 5000); // Hide message after 5 seconds
    }
  };

  return (
    <div id="contact" className="bg-gradient-to-r from-primary to-secondary py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-bold text-center text-white">
          Get in Touch
        </h2>
        <p className="mt-4 text-center text-lg text-white">
          We'd love to hear from you.
        </p>
        <div className="mt-12 max-w-xl mx-auto">
          {submitted && (
            <div className="mb-4 text-center text-white font-semibold animate-pulse">
              Your message has been sent!
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-white">Name</label>
              <input
                name="name"
                type="text"
                value={formState.name}
                onChange={handleChange}
                className={`w-full mt-2 p-3 rounded-lg focus:outline-none ${
                  formErrors.name
                    ? 'border-2 border-red-500'
                    : 'border-2 border-white focus:border-yellow'
                } bg-transparent text-white placeholder-white`}
                placeholder="Your Name"
              />
              {formErrors.name && (
                <p className="text-red-300 text-sm mt-1">{formErrors.name}</p>
              )}
            </div>
            {/* Email Field */}
            <div>
              <label className="block text-white">Email</label>
              <input
                name="email"
                type="email"
                value={formState.email}
                onChange={handleChange}
                className={`w-full mt-2 p-3 rounded-lg focus:outline-none ${
                  formErrors.email
                    ? 'border-2 border-red-500'
                    : 'border-2 border-white focus:border-yellow'
                } bg-transparent text-white placeholder-white`}
                placeholder="your.email@example.com"
              />
              {formErrors.email && (
                <p className="text-red-300 text-sm mt-1">{formErrors.email}</p>
              )}
            </div>
            {/* Message Field */}
            <div>
              <label className="block text-white">Message</label>
              <textarea
                name="message"
                value={formState.message}
                onChange={handleChange}
                className={`w-full mt-2 p-3 rounded-lg focus:outline-none ${
                  formErrors.message
                    ? 'border-2 border-red-500'
                    : 'border-2 border-white focus:border-yellow'
                } bg-transparent text-white placeholder-white`}
                rows="5"
                placeholder="Your Message"
              ></textarea>
              {formErrors.message && (
                <p className="text-red-300 text-sm mt-1">{formErrors.message}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-yellow text-gray-900 p-3 rounded-lg hover:bg-yellow-500 transition duration-300 font-semibold"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
