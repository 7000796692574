// src/components/Services.js
import React from 'react';
import { FaChartLine, FaLaptopCode, FaPalette } from 'react-icons/fa';

function Services() {
  return (
    <div id="services" className="bg-white py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl md:text-5xl font-bold text-center text-gray-800">
          What We Offer
        </h2>
        <p className="mt-4 text-center text-lg text-gray-600">
          We offer a range of services to help your business grow.
        </p>
        <div className="mt-12 grid gap-8 md:grid-cols-3">
          {/* Service 1 */}
          <div className="bg-gradient-to-b from-primary to-secondary p-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition duration-300">
            <div className="text-white text-5xl mb-4 flex justify-center">
              <FaChartLine />
            </div>
            <h3 className="text-2xl font-semibold text-white text-center">
              Growth Marketing
            </h3>
            <p className="mt-4 text-white text-center">
              We develop strategies that drive measurable growth for your business.
            </p>
          </div>
          {/* Service 2 */}
          <div className="bg-gradient-to-b from-primary to-secondary p-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition duration-300">
            <div className="text-white text-5xl mb-4 flex justify-center">
              <FaPalette />
            </div>
            <h3 className="text-2xl font-semibold text-white text-center">
              Web Design
            </h3>
            <p className="mt-4 text-white text-center">
              Our design team creates stunning websites that engage your audience.
            </p>
          </div>
          {/* Service 3 */}
          <div className="bg-gradient-to-b from-primary to-secondary p-8 rounded-lg shadow-lg hover:shadow-2xl transform hover:-translate-y-2 transition duration-300">
            <div className="text-white text-5xl mb-4 flex justify-center">
              <FaLaptopCode />
            </div>
            <h3 className="text-2xl font-semibold text-white text-center">
              Web Development
            </h3>
            <p className="mt-4 text-white text-center">
              We build robust and scalable web applications tailored to your needs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
