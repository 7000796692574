// src/components/Testimonials.js
import React, { useState } from 'react';

function Testimonials() {
  const testimonials = [
    {
      quote:
        'Oknenor transformed our online presence. Their team is exceptional!',
      client: 'Jane Doe',
      role: 'Marketing Manager',
      company: 'Tech Innovators Inc.',
      image: 'https://randomuser.me/api/portraits/women/44.jpg',
    },
    {
      quote:
        'The customer service at Oknenor is unparalleled. They went above and beyond to meet our needs.',
      client: 'John Smith',
      role: 'CEO',
      company: 'Startup Hub',
      image: 'https://randomuser.me/api/portraits/men/46.jpg',
    },
    {
      quote:
        'Thanks to Oknenor, our sales have increased by 30% in just three months!',
      client: 'Emily Johnson',
      role: 'Sales Director',
      company: 'Global Markets',
      image: 'https://randomuser.me/api/portraits/women/65.jpg',
    },
    {
      quote:
        'Their innovative solutions and attention to detail are top-notch.',
      client: 'Michael Brown',
      role: 'Product Manager',
      company: 'Creative Solutions',
      image: 'https://randomuser.me/api/portraits/men/52.jpg',
    },
    {
      quote:
        'Oknenor\'s expertise in digital marketing has been a game-changer for our business.',
      client: 'Sophia Lee',
      role: 'Founder',
      company: 'HealthPlus',
      image: 'https://randomuser.me/api/portraits/women/68.jpg',
    },
    {
      quote:
        'Professional, reliable, and results-driven. Highly recommend Oknenor!',
      client: 'David Wilson',
      role: 'Operations Manager',
      company: 'LogiTech',
      image: 'https://randomuser.me/api/portraits/men/75.jpg',
    },
  ];

  const [current, setCurrent] = useState(0);

  const nextTestimonial = () => {
    setCurrent((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrent((prev) =>
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  return (
    <div id="testimonials" className="py-16 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-800">
          What Our Clients Say
        </h2>
        <div className="mt-12 max-w-3xl mx-auto relative">
          <div className="bg-white p-8 rounded-lg shadow text-center">
            <img
              src={testimonials[current].image}
              alt={testimonials[current].client}
              className="w-20 h-20 rounded-full mx-auto mb-4 object-cover"
            />
            <p className="text-gray-600 italic text-lg">
              "{testimonials[current].quote}"
            </p>
            <p className="mt-6 text-gray-800 font-semibold">
              {testimonials[current].client}
            </p>
            <p className="text-gray-500">
              {testimonials[current].role} at {testimonials[current].company}
            </p>
          </div>
          {/* Navigation Buttons */}
          <button
            onClick={prevTestimonial}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow hover:bg-gray-200"
            aria-label="Previous Testimonial"
          >
            &lt;
          </button>
          <button
            onClick={nextTestimonial}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow hover:bg-gray-200"
            aria-label="Next Testimonial"
          >
            &gt;
          </button>
          {/* Indicators */}
          <div className="flex justify-center mt-4 space-x-2">
            {testimonials.map((_, index) => (
              <span
                key={index}
                className={`w-3 h-3 rounded-full ${
                  index === current ? 'bg-blue-600' : 'bg-gray-300'
                } cursor-pointer`}
                onClick={() => setCurrent(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
